@use "allfonts";

//Theme Variables;
$font-spartan: Spartan;
$font-roboto-slab: "Roboto Slab", serif;
$font-inherit: inherit;
$text-s-semibold: Inter;
$font-plus-jakarta-sans: "Plus Jakarta Sans";
$text-m-semi-bold-size: 0.94rem;
$font-size-xs: 0.75rem;
$text-s-semibold-size: 0.88rem;
$font-size-xl-5: 1.28rem;
$font-size-base: 1rem;
$font-size-xl: 1.25rem;
$font-size-lg: 1.13rem;
$color-darkslateblue-100: #3e2d71;
$color-darkslateblue-200: #21153b;
$color-darkslateblue-300: #2e215573;
$color-darkslateblue-400: #21184100;
$color-darkslateblue-500: #2e2155ba;
$color-darkslateblue-600: #201c40;
$color-white: #fff;
$color-lavender-200: #dbd5e4d6;
$color-lavender-300: #dbd5e466;
$color-lavender-400: #dbd5e4ba;
$color-gray-100: #fdfeff;
$color-gray-400: #18112b99;
$color-gray-700: #fffc;
$color-gray-1000: #ffffff7a;
$color-silver: #bbb;
$color-turquoise: #03e1c336;
$color-gold: #ffcb29;
$color-thistle: #dfd4ff24;
$br-7xs: 6px;
$egg-blue: #04dec5;

@mixin blue-linear-gradient() {
    background: rgb(4, 222, 197);
    background: linear-gradient(
        90deg,
        rgba(4, 222, 197, 1) 0%,
        rgba(27, 128, 254, 1) 50%,
        rgba(3, 225, 195, 1) 100%
    );
}

// Bootstrap Conf

@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";
$enable-responsive-font-sizes: true; // Enabling Responsive Font Sizing
@import "../../../../node_modules/bootstrap/scss/mixins";
@import "../../../../node_modules/bootstrap/scss/root";
// @import "../../../../node_modules/bootstrap/scss/reboot";
@import "../../../../node_modules/bootstrap/scss/type";
@import "../../../../node_modules/bootstrap/scss/images";
@import "../../../../node_modules/bootstrap/scss/grid";
@import "../../../../node_modules/bootstrap/scss/tables";
@import "../../../../node_modules/bootstrap/scss/forms";
// @import "../../../../node_modules/bootstrap/scss/buttons";
@import "../../../../node_modules/bootstrap/scss/transitions";
@import "../../../../node_modules/bootstrap/scss/dropdown";
@import "../../../../node_modules/bootstrap/scss/button-group";
@import "../../../../node_modules/bootstrap/scss/input-group";
@import "../../../../node_modules/bootstrap/scss/nav";
// @import "../../../../node_modules/bootstrap/scss/navbar";
@import "../../../../node_modules/bootstrap/scss/progress";
// @import "../../../../node_modules/bootstrap/scss/media";
@import "../../../../node_modules/bootstrap/scss/list-group";
@import "../../../../node_modules/bootstrap/scss/pagination";
// @import "../../../../node_modules/bootstrap/scss/close";
// @import "../../../../node_modules/bootstrap/scss/modal";
// @import "../../../../node_modules/bootstrap/scss/utilities";

@import "../../../../node_modules/swiper/modules/effect-cards/effect-cards.scss";
@import "../../../../node_modules/swiper/modules/effect-coverflow/effect-coverflow.scss";

@import "../../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "../../../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "../../../../node_modules/@fortawesome/fontawesome-free/scss/brands.scss";

section#statistics {
    .single-stat-wrapper {
        .stat-caption {
            font-size: $font-size-base;
            color: $color-gray-1000;
            font-family: $font-roboto-slab;
        }

        .stat-title {
            letter-spacing: -0.01em;
            font-weight: 800;
            // line-height: 4.05rem;
            font-family: $font-spartan;
            color: $color-white;
            text-align: center;
        }
    }
}

#about-us {
    .about-us-single-col {
        .about-us-single-wrapper {
            background:
                url("https://cdn.spartanpoker.com/media/svg/main_grain.webp")
                    100%/10% repeat,
                linear-gradient(
                    120deg,
                    rgb(39, 28, 70) 0%,
                    rgba(123, 100, 193, 0.4) 53%,
                    rgba(54, 39, 98, 0.1) 87%
                );
            border-radius: 0.5rem;
            height: 110px;
            border: 1.2px solid rgba(86, 70, 146, 0.9);
            @include media-breakpoint-up(md) {
                min-height: 100px;
            }

            .about-us-single-caption {
                color: $color-white;
                font-family: $font-spartan;
                line-height: 0.8rem;
                font-size: 70%;
                @include media-breakpoint-up(md) {
                    line-height: 1.5rem;
                    font-size: 100%;
                }
            }
        }
    }
}

section#play-responsibly {
    .responsible-item-title {
        color: white;
        font-family: $font-spartan;
        font-size: $font-size-xl;
        font-weight: 600;
    }
    .responsible-item-caption {
        font-size: $font-size-base;
        line-height: 1.25rem;
        font-family: $font-roboto-slab;
        color: $color-lavender-400;
    }

    .marquee {
        @keyframes marquee {
            from {
                transform: translateX(0);
            }
            to {
                transform: translateX(-150%);
            }
        }

        position: relative;
        width: 100%;
        max-width: 100%;
        min-height: 18rem;
        // overflow-x: auto;

        // .track {
        //   position: absolute;
        //   white-space: nowrap;
        //   will-change: transform;
        //   animation: marquee 12s linear infinite;
        //   @include media-breakpoint-up(md){
        //     animation: marquee 32s linear infinite;
        //   }

        //   &:hover {
        //     animation-play-state: paused;
        //   }
        // }

        .responsible-single-wrapper {
            width: 300px;
            margin: 0 1rem;
            display: inline-block;
            white-space: normal;
            word-wrap: normal;

            .responsible-single-item {
                width: 100%;
                height: 100%;
            }
        }
    }
}

section#blogs {
    .blogs-single-wrapper {
        border-radius: 0.8rem;
        min-height: 13rem;
        height: 13rem;
        background:
            url("https://cdn.spartanpoker.com/media/svg/main_grain.webp") 100%/10%
                repeat,
            linear-gradient(
                120deg,
                rgb(39, 28, 70) 0%,
                rgba(123, 100, 193, 0.4) 53%,
                rgba(54, 39, 98, 0.1) 87%
            );
        border-radius: 0.8rem;
        border: 1.2px solid rgba(86, 70, 146, 0.9);
        @include media-breakpoint-up(md) {
            min-height: 15rem;
            height: 15rem;
        }

        .blogs-img-wrapper img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: 0.5rem;
        }

        .blog-date {
            color: $color-lavender-400;
            font-family: $font-roboto-slab;
        }

        .blog-title {
            font-weight: 600;
            line-height: 1.3rem;
            font-family: $font-spartan;
            color: white;
            text-align: left;
            font-size: 1rem;
            @include media-breakpoint-up(md) {
                line-height: 1.65rem;
                font-size: $font-size-lg;
            }
        }

        .blog-excerpt {
            // font-size:$text-s-semibold-size;
            line-height: 0.5rem;
            font-family: $font-roboto-slab;
            color: $color-lavender-400;
            text-align: left;
            max-height: 75px;
            overflow: hidden;
            font-size: 2vw;
            @include media-breakpoint-up(md) {
                font-size: 1vw;
                line-height: 1.15rem;
                overflow: hidden;
            }
        }
    }
}

section#pioneer {
    background-color: $color-gray-400;

    .swiper-wrapper {
        @include media-breakpoint-down(md) {
            aspect-ratio: 16/7;
        }
        width: 370px;
        @include media-breakpoint-up(md) {
            width: 850px;
        }

        .swiper-slide,
        .pioneer-swiper-slide {
            border: 2.5px solid #3c3057;
            // outline: 2.5px solid #3e2d71;
            box-shadow: 0px 0px 1px #3e2d71;
            border-radius: 2rem;
            overflow: hidden;
            @include media-breakpoint-up(md) {
                width: 600px;
                height: 300px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .play-video-button {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

section#subscribe {
    background-color: $color-gray-400;
}

section#faq {
    font-family: $font-spartan;
    .nav-pills {
        @include media-breakpoint-up(md) {
            background-color: $color-darkslateblue-300;
        }

        .nav-link {
            border-radius: $br-7xs;
            background-color: $color-darkslateblue-400;
            border: 1px solid $color-lavender-300;
            box-sizing: border-box;
            margin: 0.3rem 0;
            color: white;
            flex: 1 0 auto;

            &.active,
            &:hover {
                background: rgba(33, 24, 65, 1);
                position: relative;
                border: 1px solid transparent;
                background-clip: padding-box;

                p {
                    background: linear-gradient(
                        90deg,
                        rgb(4, 222, 197) 0%,
                        rgb(27, 128, 254) 50%,
                        rgb(3, 225, 195) 100%
                    );
                    background-clip: border-box;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-weight: bold;
                    transition: font-weight 250ms ease-in-out;
                }

                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: inherit;
                    background: linear-gradient(
                        90deg,
                        rgb(4, 222, 197) 0%,
                        rgb(27, 128, 254) 50%,
                        rgb(3, 225, 195) 100%
                    );
                    z-index: -1;
                    margin: -2px;
                }
            }
        }
    }

    .accordion {
        .question-set {
            border-bottom: 1px solid #5c43a9;
        }

        .question-text {
            cursor: pointer;
            position: relative;
            padding: 1rem 0;
            &:after {
                font-family: "Font Awesome 6 Free";
                position: absolute;
                color: white;
                top: 1rem;
                right: 0;
                pointer-events: none;
            }
            &[aria-expanded="false"]:after {
                content: "\2b";
            }
            &[aria-expanded="true"]:after {
                content: "\f068";
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                font-size: 1rem !important;
                color: white !important;
            }
        }
        .question-wrapper {
            color: white;
            margin: 0.2rem 0;
            padding: 0;
        }
        .answer-wrapper {
            font-size: $font-size-base;
            line-height: 1.5rem;
            font-family: $font-roboto-slab;
            color: $color-lavender-400;
            padding: 0.2 0;
        }
    }
}

// Top Poker Games Section
section#top-poker-games {
    @for $i from 1 through 7 {
        .games-single-item-wrapper:nth-child(#{$i}) .big-2 {
            left: random($limit: 50) + 1%;
            top: random($limit: 50) + 1%;
            bottom: 100%;
        }
    }
    .games-single-item-wrapper {
        .games-item-inner-wrapper {
            border: 2.5px solid $color-thistle;
            // outline:2.5px solid $color-darkslateblue-100;
            box-shadow: 0px 0px 2px $color-darkslateblue-100;
            border-radius: 2rem;
            height: 100%;
            width: 100%;
            position: relative;
            height: 13rem;
            max-height: 13rem;
            min-height: 13rem;
            @include media-breakpoint-up(md) {
                min-height: 235px;
            }

            .games-item-img img {
                position: relative;
                display: inline-block;
                top: -30%;
                left: -30%;
                @include media-breakpoint-up(md) {
                    left: -14%;
                }
            }

            &:after {
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.5);
                // backdrop-filter: blur(20px);
                z-index: -1;
                position: absolute;
                top: 0;
                left: 0;
                border-radius: inherit;
            }

            .bg-shape {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                position: absolute;
                bottom: 0;
                opacity: 0.1;
                pointer-events: none;

                &.big {
                    width: 150px;
                    height: 150px;
                    right: -25%;
                    top: -15%;
                    bottom: 100%;
                }
            }
            .games-item-title {
                @include blue-linear-gradient();
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-family: $font-spartan;
                font-weight: 900;
                margin-bottom: 0;
                width: 130%;

                & + .title-break {
                    color: white;
                    font-family: $font-spartan;
                    font-weight: bold;
                    color: white;
                }
            }
            .games-item-details-row {
                max-height: 120px;
            }

            .games-item-description {
                font-family: $font-roboto-slab;
                color: $color-lavender-200;
                text-align: center;

                @include media-breakpoint-down(md) {
                    &.small {
                        font-size: 75%;
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        .top-poker-games-swiper .swiper-slide {
            width: 350px;
        }
    }
}

section#tutorials {
    .tutorial-item-inner-wrapper {
        border-radius: 2rem;
        padding-top: 56.25%;
        position: relative;
        height: 0;
        // overflow: hidden;

        &:after {
            content: "";
            display: block;
            width: 100%;
            height: 95%;
            position: absolute;
            border-radius: 2rem;
            background-color: rgba(0, 0, 0, 0.6);
            z-index: +1;
            top: 0;
            left: 0;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            max-width: 100%;
            height: auto;
            border-radius: 2rem;
        }

        .play-video-button {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: +3;
        }

        .tutorial-swiper {
            .swiper-slide {
                // width:200px;
                height: 100px;
            }
        }
        .tutorial-title,
        .tutorial-description {
            color: white;
            text-align: left;
            position: absolute;
            z-index: +2;
            left: 8%;
        }
        .tutorial-title {
            top: 10%;
            font-family: $font-spartan;
            padding-right: 1rem;
        }
        .tutorial-description {
            bottom: 10%;
            font-family: $font-roboto-slab;
        }
    }
}

section#promotions {
    .promotion-item-inner-wrapper {
        background: rgb(65, 105, 184);
        background: linear-gradient(
            135deg,
            rgba(65, 105, 184, 1) 39%,
            rgba(52, 162, 138, 1) 81%
        );
        padding: 0.5rem;
        border-radius: 1.5rem;
        @include media-breakpoint-down(md) {
            min-height: 350px;
        }
    }

    .promotion-title {
        color: $color-lavender-400;
        font-family: $font-roboto-slab;
    }
    .promotion-detail-number {
        color: white;
        font-family: $font-spartan;
        font-weight: 800;
        margin-bottom: 0.1rem;
    }
    .promotion-detail-description {
        color: white;
        font-family: $font-spartan;
    }
    .promotion-detail-code {
        color: white;
        font-family: $font-roboto-slab;
        font-weight: 600;
        color: $warning;
        margin-bottom: 0.1rem;

        &:hover {
            color: white;
        }
    }
    .promotion-detail-cta {
        color: white;
    }
}

// promotion listing page
.promotion-single-item-wrapper {
    border: 3px solid #544585;
    position: relative;
    background-color: #21153b;
    border-radius: 2rem;
    overflow: hidden;
    box-shadow: 0 0 0 3px #3a2a69;

    &:hover .promotion-item-inner-wrapper {
        opacity: 1;
    }

    .promotion-item-inner-wrapper {
        display: flex;
        background: rgb(33, 30, 59);
        background: linear-gradient(
            0deg,
            rgba(33, 30, 59, 0.9091036072632178) 0%,
            rgba(33, 30, 59, 0.7438375008206408) 33%,
            rgba(33, 30, 59, 0) 100%
        );
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 1rem;
        transition: opacity 250ms ease-in-out;
        @include media-breakpoint-up(md) {
            background: rgba(0, 0, 0, 0.9);
            opacity: 0;
        }

        .link-next {
            background: linear-gradient(180deg, #382364 0%, #4b3087 100%);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            width: 35px;
            height: 35px;
            border-radius: 50%;
            display: inline-block;
            @include media-breakpoint-up(md) {
                flex: 1 0 35px;
            }
            .flip-right {
                transform: rotate(180deg);
            }
        }
        .promotion-item-title {
            position: absolute;
            bottom: 10%;
            letter-spacing: -0.02em;
            text-transform: capitalize;
            background: linear-gradient(180deg, #00efbb 0%, #1b80fe 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            font-family: $font-spartan;
            font-weight: 700;
            @include media-breakpoint-up(md) {
                position: relative;
                bottom: 0;
            }
        }
        .promotion-item-description {
            display: none;
            @include media-breakpoint-up(md) {
                display: block;
                font-family: $font-roboto-slab;
                font-size: 0.8rem;
                color: $color-lavender-400;
            }
        }
    }
}
// Prommotion listing page
section#join-community {
    .community-form-wrapper {
        position: relative;
        color: white;
        background: #180f2a;
        background-clip: padding-box;
        $border: 0.1rem;
        border: $border solid transparent;
        border-radius: 0.5rem;
        text-align: center;
        vertical-align: middle;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -$border;
            border-radius: inherit;
            background: linear-gradient(
                94.32deg,
                #a96a14,
                #e6a64d 26.87%,
                #ffe891 63.85%,
                #bb842a
            );
        }

        .community-text-title {
            font-family: $font-spartan;
            text-align: left;
            font-weight: 600;
            letter-spacing: -0.02em;
            @include media-breakpoint-down(md) {
                font-size: calc(1rem + 0.6vw);
            }

            span:first-child {
                background: linear-gradient(
                    94.32deg,
                    #a96a14,
                    #e6a64d 26.87%,
                    #ffe891 63.85%,
                    #bb842a
                );
                background-clip: border-box;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            span:last-child {
                color: white;
            }
        }

        .community-form-inner-wrapper {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            height: 100%;
            display: block;
            z-index: 0;
            pointer-events: none;
            background: url("https://cdn.spartanpoker.com/media/png/community_bg.webp")
                110px/130% no-repeat;
            @include media-breakpoint-up(md) {
                background: url("https://cdn.spartanpoker.com/media/png/community_bg.webp")
                    350px / cover no-repeat;
            }
        }

        form #community-number {
            font-family: $font-spartan;
            color: white;
            background-color: #261e37;
            border: none;
            position: relative;

            &:after {
                content: "asldkfjasdf";
                position: absolute;
                font-family: inherit;
                color: inherit;
            }
        }
        .phone-input-wrapper {
            position: relative;

            &:before {
                content: "+91";
                position: absolute;
                font-family: $font-spartan;
                padding: 0.44rem 1rem;
                color: white;
                top: 0;
                left: 0;
                font-size: inherit;
                z-index: +1;
                height: 100%;
                display: inline-block;
                font-size: 1rem;
                line-height: 1.5;
            }
        }
    }
}

section#trending-stories {
    .eclipse-shape-blue {
        top: 0;
        left: 0;
    }
    .eclipse-shape-green {
        opacity: 0.1;
    }
    .eclipse-shape-yellow {
        position: absolute;
        left: 25%;
        top: 50%;
        opacity: 0.1;
        pointer-events: none;
        width: 100%;
        height: 100%;
        z-index: 1;
        border-radius: 10%;
    }

    // .stories-item-wrapper{
    // }
    .stories-item {
        background-color: #1f152b;
        position: relative;
        border: 3.5px solid $color-thistle;
        // outline:3.5px solid $color-darkslateblue-100;
        box-shadow: 0px 0px 2px $color-darkslateblue-100;
        width: 120px;
        height: 120px;

        img {
            // position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 0 0 10rem 10rem;
            top: -05%;
            left: 50%;
            // transform: translateX(-50%);
        }

        .play-video-button {
            position: absolute;
            bottom: -10%;
            left: 50%;
            transform: translateX(-50%);
            width: 30px;
            height: 30px;
        }

        &:after {
            content: "";
            background: url("https://cdn.spartanpoker.com/media/png/story-border.png")
                100% / cover no-repeat;
            display: inline-block;
            width: 90%;
            height: 90%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .story-person-name {
            position: absolute;
            bottom: -45%;
            color: white;
            font-family: $font-spartan;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            white-space: nowrap;
        }
    }
}

.playnow-swiper {
    height: 270px;
    width: 270px;

    @include media-breakpoint-up(md) {
        height: 300px;
        width: 500px;
    }
}

.play-now-item {
    position: relative;
    display: block;
    z-index: 0;
    height: 100%;
    background:
        url("https://cdn.spartanpoker.com/media/svg/main_grain.webp") 100%/10%
            repeat,
        linear-gradient(
            120deg,
            rgb(39, 28, 70) 0%,
            rgba(123, 100, 193, 0.4) 53%,
            rgba(54, 39, 98, 0.1) 87%
        ),
        #21153b;
    border: 2px solid rgba(86, 70, 146, 0.7);
    border-radius: 0.6rem;
    // padding-top:54%;
    // background: url("https://cdn.spartanpoker.com/media/png/grain_bg_phone.png") left top/contain no-repeat,#21153b;

    @include media-breakpoint-up(md) {
        background:
            url("https://cdn.spartanpoker.com/media/svg/main_grain.webp") 100%/10%
                repeat,
            linear-gradient(
                120deg,
                rgb(39, 28, 70) 0%,
                rgba(123, 100, 193, 0.4) 53%,
                rgba(54, 39, 98, 0.1) 87%
            ),
            #21153b;
        border: 2px solid rgba(86, 70, 146, 0.7);
        border-radius: 0.6rem;

        width: 500px;
        // height: 300px;
        height: 272px;
        padding-top: 27%;
    }

    .play-now-item-img {
        position: absolute;
        left: -5%;
        top: -4%;
        max-width: 140px;
        @include media-breakpoint-up(md) {
            left: -15%;
            top: 15%;
            max-width: 250px;
        }
    }
}

.table-details-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: +1;
    .table-details-oblique-ruppees {
        position: relative;
        color: white;
        background: linear-gradient(180deg, #523492 -25%, #311f58 125%);
        background-clip: padding-box;
        $border: 0.1rem;
        border: $border solid transparent;
        border-radius: 0.5rem;
        text-align: center;
        vertical-align: middle;
        font-family: $font-roboto-slab;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -$border;
            border-radius: inherit;
            background: linear-gradient(
                94.32deg,
                #a96a14,
                #e6a64d 26.87%,
                #ffe891 63.85%,
                #bb842a
            );
        }
    }
    .table-details-table-name {
        @include media-breakpoint-down(md) {
            margin-top: 25%;
        }
        font-family: $font-spartan;
        color: white;
        line-height: 1.25em;
        font-weight: 700;
    }
    .table-details-blinds {
        font-family: $font-roboto-slab;
        color: $color-lavender-400;
    }
    .table-details-progress-wrapper {
        .progress {
            border: 0.6px solid $color-turquoise;
            border-radius: 5.85px;
            background: linear-gradient(#5c43a9, #2e2155);
            .progress-bar {
                background: linear-gradient(#00efbb, #1b80fe);
                border-radius: 5.85px;
                box-shadow: inset 0 4.68294px 4.68px #00efbb;
            }
        }
    }
    .table-details-game-type {
        .game-type {
            color: white;
            text-transform: uppercase;
            font-family: $font-roboto-slab;
            text-align: left;
            @include media-breakpoint-down(md) {
                font-size: 65%;
            }
        }
        .number-of-players {
            color: $color-lavender-400;
            font-family: $font-roboto-slab;
            text-align: right;
        }
    }
}

// Modal Styling
#login-modal .modal-content,
#signup-modal .modal-content,
#install-modal .modal-content {
    border-radius: 2rem;
    background-color: $color-darkslateblue-200;
    text-align: center;

    .g-recaptcha {
        display: flex;
        justify-content: center;
    }

    .signUp-form-header {
        position: relative;
        padding: 1rem;

        .signUp-form-header-text {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            flex-direction: column;
            margin-top: 1rem;
        }
        .signUp-form-header-text-bot {
            color: $color-lavender-300;
            font-family: $font-spartan;
            text-align: center;
            font-size: 0.9rem;
        }
        .signUp-form-header-text-mid {
            font-family: $font-spartan;
            font-weight: 700;
            background: linear-gradient(
                90.54deg,
                #a96a14 0.51%,
                #e6a64d 28.89%,
                #ffe891 69.14%,
                #bb842a 99.59%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            display: inline-block;
        }

        & .close {
            opacity: 1;
            width: 32px;
            height: 32px;
            background: linear-gradient(180deg, #382364 0%, #4b3087 100%);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 50%;
            position: absolute;
            top: 10%;
            right: 5%;

            &:after {
                content: url("https://cdn.spartanpoker.com/media/png/close-x.png");
                position: absolute;
                font-family: $font-spartan;
                font-weight: 100;
                background: linear-gradient(
                    94.32deg,
                    #a96a14,
                    #e6a64d 26.87%,
                    #ffe891 63.85%,
                    #bb842a
                );
                background-clip: border-box;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                display: inline-block;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            img {
                display: none;
            }
        }
    }

    .signUp-form-header-text-top {
        color: $color-lavender-300;
    }

    .form-group {
        padding: 0rem 1rem;
    }

    input.form-control {
        background: #2f2448;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        border: none;
        color: white;
    }

    [type="submit"] {
        font-size: 1rem;
        font-family: $font-roboto-slab;
        text-align: center;
        vertical-align: top;
        border-image: url("https://cdn.spartanpoker.com/media/svg/fill-button-2.svg");
        border-image-slice: 25 40 17 17;
        border-image-width: 40px 70px 25px 30px;
        border-image-outset: 0px 0px 0px 0px;
        border-image-repeat: stretch stretch;
        color: black;
        position: relative;
        z-index: +1;
        padding: 0.5rem 2.2rem;
        line-height: 1.5;
        display: inline-block;
        font-weight: 400;
        background-color: transparent;
        width: 94%;
        margin: 0 3%;

        &:after {
            content: "";
            width: 100%;
            height: 100%;
            // background: url('https://cdn.spartanpoker.com/media/png/logo-transparent.png') 95%/contain no-repeat;
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            opacity: 40%;
        }
    }

    .forgot-password {
        font-family: $font-spartan;
        text-align: right;
        padding: 0 1rem;
        a {
            font-family: inherit;
            color: white;
            text-decoration: underline;
            font-weight: 500;
        }
    }
    .form-check.promo-code {
        padding: 0rem 1rem;
        color: white;
        text-align: left;

        .form-check-input {
            margin-left: 0;
        }
        .form-check-label {
            margin-left: 1rem !important;
            color: white;
            text-transform: capitalize;
            text-decoration: underline;
            font-weight: 600;
        }
    }
    .terms-of-use {
        color: $color-lavender-300;
        padding: 0 0.5rem;
        a {
            color: inherit;
        }
    }

    .signup-link {
        margin: 0.5rem 0;
        color: white;
        font-family: $font-spartan;

        a {
            text-decoration: underline;
            font-weight: 500;
            color: white;
        }
    }

    @keyframes loading1 {
        0% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .load::after,
    .load::before {
        content: "";
        width: 30px;
        height: 30px;
    }

    .load,
    .load::after,
    .load::before {
        display: flex;
        position: absolute;
    }

    .load {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: inherit;
        align-items: center;
        justify-content: center;
        border-radius: inherit;
    }
    .activeLoading .load.loader.dark {
        opacity: 1;
    }
    .load::after {
        border-radius: 50%;
        border: 3px solid #fff;
        border-left: 3px solid transparent;
        border-bottom: 3px solid transparent;
        animation: 1s infinite loading1;
        z-index: 10;
    }
    .load::before {
        border-radius: 50%;
        border: 3px dashed #fff;
        border-left: 3px solid transparent;
        border-bottom: 3px solid transparent;
        animation: 2s linear infinite loading1;
        z-index: 5;
    }
    .loader.dark {
        width: 30px;
        height: 30px;
        position: absolute;
        right: 4rem;
        bottom: 1rem;
        display: inline;
        opacity: 0;
        pointer-events: none;
    }
    .activeLoading .loader.dark {
        opacity: 1;
    }
    .loader.dark::before {
        content: "";
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0;
        border: 3px dashed #25aae1;
        border-left: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-radius: 50%;
        animation: 2s linear infinite loading1;
        z-index: 5;
        width: 100%;
        height: 100%;
    }
    .loader.dark::after {
        content: "";
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0;
        border: 3px solid #25aae1;
        border-left: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-radius: 50%;
        animation: 1s linear infinite loading1;
        z-index: 6;
        width: 100%;
        height: 100%;
    }

    button.activeLoading .loading,
    .activeLoading .loading {
        visibility: visible;
        opacity: 1;
    }
    button .loading {
        opacity: 0;
        visibility: hidden;
    }
}

.body-cover {
    transition: background-color 0.3s ease-in-out;
}
.body-cover.appear {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1031;
    top: 0;
    left: 0;
}

// TESTING

.main-nav-list {
    font-family: $font-spartan;
}

.main-nav-list > .main-nav-item > .main-nav-link {
    margin-bottom: 0.6rem;

    &:after {
        content: "\f054";
        font-family: "Font Awesome 6 Free";
        color: white;
        margin-left: auto;
        display: inline-block;
        position: absolute;
        right: 10%;
        z-index: -1;
        transition: rotate 250ms ease-in;
        transform: rotate(90deg);
    }
    &.up:after {
        transition: rotate 250ms ease-in;
        transform: rotate(270deg);
    }
    &:hover {
        text-decoration: none;
    }
}

ul.category {
    list-style-type: none;
    padding-left: 0.3rem;
}

.category-header {
    color: $color-lavender-200;
    &:after {
        content: "";
        height: 1px;
        width: 35%;
        border-bottom: 1px solid $color-lavender-200;
        display: block;
    }
}
.main-nav-list .sub-menu ul li a {
    color: white;
}

.main-nav-list .sub-menu ul li a:hover {
    color: white;
    font-weight: 700;
    text-decoration: none;
}

.main-nav-wrapper .main-nav > ul li {
    text-align: center;
    margin: 0 0 0.5rem;
}

.main-nav-list .sub-menu {
    will-change: max-height;
}

.main-nav-list .sub-menu {
    max-height: 0;
    transition: max-height 250ms ease-out;
    background-color: transparent;
    overflow-y: auto;
    margin-bottom: 0.5rem;
}

.main-nav-list .sub-menu.show {
    max-height: 25rem;
}
.sub-menu li {
    margin: 0 !important;
    padding: 0.2rem 0;
}

footer {
    p {
        font-size: 0.7rem;
    }
    font-family: $font-spartan;

    .footer-col {
        margin-bottom: 0.3rem;
        a {
            display: block;
            color: white;
            font-family: $font-spartan;
            font-size: 0.7rem;
            &:hover {
                font-weight: 700;
                color: $egg-blue;
                text-decoration: none;
            }
        }

        @include media-breakpoint-up(lg) {
            & [data-parent="#foot-nav-accordion"].collapse {
                display: block;
            }
        }
    }

    .footer-col-heading {
        @include media-breakpoint-down(md) {
            background-color: white;
            border-radius: 0.5rem;
            padding: 0.4rem 0.4rem;
            position: relative;
            color: black;
            font-size: 80%;
            &:after {
                content: "+";
                position: absolute;
                right: 5%;
                top: 50%;
                transform: translateY(-50%);
                color: black;
                font-family: inherit;
                font-size: 2rem;
            }
            &[aria-expanded="true"]:after {
                content: "-";
            }
            @include media-breakpoint-up(md) {
                pointer-events: none;
            }
        }

        color: white;
        font-family: $font-roboto-slab;
        font-weight: 700;
    }

    .footer-stat-box {
        @include media-breakpoint-down(md) {
            justify-content: space-between;
            padding: 0 0.3rem;

            .footer-stat-item:nth-child(1) {
                flex: 0 1 40%;
            }
            .footer-stat-item:nth-child(2) {
                flex: 0 1 12.5%;
            }
            .footer-stat-item:nth-child(3) {
                flex: 0 1 25%;
            }
            .footer-stat-item:nth-child(4) {
                flex: 0 1 12.5%;
            }
        }
    }
    .download-footer {
        @include media-breakpoint-down(md) {
            a:nth-child(1) {
                flex: 1 0 40%;
            }
            a:nth-child(2) {
                flex: 1 0 40%;
            }
            a:nth-child(5) {
                background-color: transparent !important;
                flex: 0 1 33%;
            }
            a:nth-child(6) {
                background-color: transparent !important;
                flex: 0 1 33%;
            }
            a:nth-child(7) {
                background-color: transparent !important;
                flex: 0 1 33%;
            }
        }
    }
}

@mixin shine-gradient {
    background: linear-gradient(87.33deg, #100b1d, #362762 47.71%, #18112b);
}

section#week-winners {
    [class*="-winner-wrapper"] {
        margin: 0 0.5%;
        @include media-breakpoint-up(md) {
            margin: 0 1%;
        }
    }

    .winner-pedestal {
        display: inline-block;
        position: relative;

        .pedestal-top {
            position: absolute;
            top: -25px;
        }
    }

    .second-winner-wrapper {
        @include media-breakpoint-down(md) {
            flex-basis: 28%;
        }
        .winner-pedestal {
            // width:100px;
            width: 100%;
            height: 160px;
            @include shine-gradient;
            @include media-breakpoint-up(md) {
                width: 210px;
                height: 200px;
            }
        }
        .pedestal-details-wrapper {
            bottom: 25%;
            @include media-breakpoint-up(md) {
                bottom: 10%;
            }
        }
        .stories-item {
            @include media-breakpoint-down(md) {
                // filter:drop-shadow(0 0 1.5rem rgba(255, 255, 255,.5));
                width: 80px;
                height: 80px;
            }
            // filter:drop-shadow(0 0 8rem rgba(255, 255, 255,.5));
        }
        .cup_image {
            @include media-breakpoint-down(md) {
                width: 45px;
                top: -18%;
                right: 02%;
                z-index: +2;
            }
        }
    }

    .first-winner-wrapper {
        @include media-breakpoint-down(md) {
            flex-basis: 40%;
        }
        .winner-pedestal {
            @include shine-gradient;
            width: 100%;
            height: 190px;
            @include media-breakpoint-up(md) {
                width: 270px;
                height: 250px;
            }
        }
        .pedestal-details-wrapper {
            bottom: 45%;
            @include media-breakpoint-up(md) {
                bottom: 25%;
            }
        }
        .stories-item {
            + img {
                top: -13%;
                left: -33%;
                width: 140%;
                @include media-breakpoint-up(md) {
                    top: -15%;
                    left: 0;
                    width: 100%;
                }
            }
            @include media-breakpoint-down(md) {
                // filter:drop-shadow(0 0 1.4rem rgba(4, 222, 197,.5));
                width: 125px;
                height: 125px;
                margin-left: 0;
            }
            // filter:drop-shadow(0 0 8rem rgba(4, 222, 197,.5));
        }
        .cup_image {
            @include media-breakpoint-down(md) {
                width: 45px;
                top: -15%;
                right: 02%;
                z-index: +2;
            }
        }
    }

    .third-winner-wrapper {
        @include media-breakpoint-down(md) {
            flex-basis: 26%;
        }
        .winner-pedestal {
            @include shine-gradient;
            width: 100%;
            height: 130px;
            @include media-breakpoint-up(md) {
                width: 210px;
                height: 170px;
            }
        }
        .stories-item {
            @include media-breakpoint-down(md) {
                // filter:drop-shadow(0 0 1.5rem rgba(4, 222, 197,.5));
                width: 80px;
                height: 80px;
            }
            // filter:drop-shadow(0 0 8rem rgba(4, 222, 197,.3));
        }
        .cup_image {
            @include media-breakpoint-down(md) {
                width: 45px;
                top: -21%;
                right: 02%;
                z-index: +2;
            }
        }
    }

    .pedestal-details-wrapper {
        position: absolute;
        text-align: center;
        width: 100%;
        bottom: 27%;
        @include media-breakpoint-up(md) {
            bottom: 2%;
        }

        .position-title {
            @include media-breakpoint-down(md) {
                font-size: 0.7rem;
            }
            color: $color-lavender-200;
            font-family: $font-roboto-slab;
            font-weight: 700;
        }
        .winner-name {
            letter-spacing: -0.02em;
            text-transform: capitalize;
            background: linear-gradient(180deg, #00efbb 0%, #1b80fe 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            font-family: $font-spartan;
            font-weight: 500;
            @include media-breakpoint-up(md) {
                font-weight: 700;
            }
        }
        .winner-prize {
            @include media-breakpoint-down(md) {
                font-size: 0.8rem;
            }
            color: white;
            font-family: $font-spartan;
            font-weight: 800;
        }
    }

    .cup_image {
        position: absolute;
        // top:-100px;
        top: -45px;
        right: 02%;
        z-index: +2;
    }

    .stories-item {
        position: relative;
        border: 2.5px solid $color-thistle;
        box-shadow: 0px 0px 2px $color-darkslateblue-100;
        // outline:2.5px solid $color-darkslateblue-100;
        z-index: +1;
        background-color: #1f152b;
        margin: 0 auto;
        @include media-breakpoint-up(md) {
            width: 150px;
            height: 150px;
            border: 3.5px solid $color-thistle;
            box-shadow: 0px 0px 2px $color-darkslateblue-100;
        }

        img {
            // position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 0 0 10rem 10rem;
            top: -05%;
            left: 50%;
            // transform: translateX(-50%);
        }

        .play-video-button {
            position: absolute;
            bottom: -10%;
            left: 50%;
            transform: translateX(-50%);
            width: 30px;
            height: 30px;
        }

        &:after {
            content: "";
            // background: url("https://cdn.spartanpoker.com/media/png/story-border.png") 100%/cover no-repeat;
            display: inline-block;
            width: 90%;
            height: 90%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &:before {
            content: "";
            background-color: rgba(27, 128, 254, 0.1);
            filter: blur(01px);
            display: inline-block;
            width: 90%;
            height: 3%;
            position: absolute;
            bottom: -12%;
            left: 50%;
            border-radius: 50%;
            transform: translateX(-50%);
        }

        .story-person-name {
            position: absolute;
            bottom: -45%;
            color: white;
            font-family: $font-spartan;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            white-space: nowrap;
        }
    }
}

.week-winners-table-wrapper {
    border: 2px solid $color-darkslateblue-100;
    border-radius: 2rem;
    max-height: 400px;
    overflow: auto;
    box-shadow: inset 0em 0em 10px $color-darkslateblue-100;
}

.players-table {
    background: url("https://cdn.spartanpoker.com/media/svg/main_grain.webp"),
        linear-gradient(180deg, #18112b 0%, rgba(28, 19, 54, 0) 100%);
    border: 1px solid rgba(62, 45, 113, 0.66);
    box-shadow: inset 0px 0px 10px #43327a;
    border-radius: 22px;
    box-shadow: inset 0em 0em 20px #3e2d71;
    text-align: center;
    thead {
        background-color: #18112b;
        border-bottom: 3px solid #1b2c69;
        border-radius: 22px;
        th {
            background-color: #18112b;
            position: sticky;
            top: 0;
            z-index: +2;
            padding: 1rem 0;
            padding-left: 0.9rem;
            border-bottom: 3px solid #1b2c69;
            border-top: none;
            @include media-breakpoint-down(md) {
                font-size: 0.8rem;
            }
        }
        tr,
        td {
            border-top: none;
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        }
        p {
            letter-spacing: -0.02em;
            text-transform: capitalize;
            background: linear-gradient(180deg, #00efbb 0%, #1b80fe 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            font-family: $font-spartan;
            font-weight: 700;
            margin-bottom: 0.1rem;
        }
    }

    tbody {
        tr,
        td {
            vertical-align: middle;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        }
        counter-reset: ranker 3;
        // counter-set : ranker 3;
        p {
            margin-bottom: 0.1rem;
        }
    }

    // background-color: $color-darkslateblue-200;
    color: white;

    .table-player-position {
        margin-bottom: 0;
        letter-spacing: -0.02em;
        text-transform: capitalize;
        background: url("https://cdn.spartanpoker.com/media/png/pos_badge.png")
            center/contain no-repeat;
        font-weight: 700;
        font-family: $font-spartan;
        color: black;
        width: 40px;
        height: 40px;
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        padding-top: 2.5%;
        position: relative;
        &:after {
            counter-increment: ranker;
            content: "" counter(ranker) "";
            color: black;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: +1;
            width: 100%;
            height: 100%;
            padding-top: 10.5%;
        }
    }
    .table-player-name {
        font-family: $font-spartan;
        color: white;
    }
    .table-player-prize {
        font-family: $font-spartan;
        color: white;
        font-weight: 700;
        &:before {
            content: "\e1bc";
            font-family: "Font Awesome 6 Free";
            font-weight: 900;
            color: white;
            margin-right: 2px;
        }
    }
}

/* 
|-----------------
| VIDEO MODAL CSS
|----------------- 
*/
/* Video Modal
-----------------------------------------*/
.video-modal,
.video-modal .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3000;
}
.video-modal {
    overflow: hidden;
    position: fixed;
    opacity: 0;

    -webkit-transform: translate(500%, 0%);
    transform: translate(500%, 0%);

    -webkit-transition: -webkit-transform 0s linear 0s;
    transition: transform 0s linear 0s;

    /* using flexbox for vertical centering */

    /* Flexbox display */
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;

    /* Vertical alignment */
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;

    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
}
.video-modal .overlay {
    z-index: 0;
    background: rgba(0, 0, 0, 0.4); /* overlay color */

    opacity: 0;

    -webkit-transition: opacity 0.2s ease-out 0.05s;
    transition: opacity 0.2s ease-out 0.05s;
}

.video-modal-content {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    z-index: 1;

    margin: 0 auto;

    overflow-y: visible;

    background: #000;

    width: auto;
    height: 0;
    padding-top: calc((100% - 12em) * 0.5625); /* 16:9 calc */
}

/* Scaling to fit within the current Viewport size:
   When viewport aspect ratio is greater than 16:9
   work off the height instead of the width for calc */
@media (min-width: 641px) {
    .video-modal-content {
        width: 0;
        /* height: calc(100vh - 30em); */
        height: 50vh;
        padding-top: 0;
        /* padding-left: calc((100vh - 30em) * 1.7778); 16:9 calc */
        padding-left: 30em;
    }
}
/* Mobile Layout Tweaks - side margins reduced */
@media (max-width: 640px) {
    .video-modal-content {
        width: calc(100% - 1em);
        padding-top: calc((100% - 1em) * 0.5625); /* 16:9 calc */
    }
}

/* modal close button */
.close-video-modal {
    display: block;
    position: absolute;
    left: 0;
    top: -40px;

    text-decoration: none;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
}

/* set the iframe element to stretch to fit its parent element */
iframe#youtube {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;

    background: #000;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.5);
}

/* show the modal: 
   add class to the body to reveal */
.show-video-modal .video-modal {
    opacity: 1;

    transform: translate(0%, 0%);
    -webkit-transform: translate(0%, 0%);
}
.show-video-modal .video-modal .overlay {
    opacity: 1;
}
.show-video-modal .video-modal-content {
    transform: translate(0%, 0%);
    -webkit-transform: translate(0%, 0%);
}
/* 
|-----------------
| VIDEO MODAL CSS ENDS
|----------------- 
*/

.send-otp ~ .invalid-feedback,
.send-otp ~ .valid-feedback {
    display: block !important;
}

.send-otp button {
    background: linear-gradient(
        94.32deg,
        #a96a14,
        #e6a64d 26.87%,
        #ffe891 63.85%,
        #bb842a
    );
    // background: -webkit-linear-gradient(top, #1b80fe, #00efbb);
    cursor: pointer;
}

.send-otp {
    display: flex;
    background: #fff;
    white-space: nowrap;
    border-radius: 1rem;
}
.send-otp button {
    background: linear-gradient(
        94.32deg,
        #a96a14,
        #e6a64d 26.87%,
        #ffe891 63.85%,
        #bb842a
    );
    // color: #fff;
    color: black;
    padding: 5px 1rem;
    font-size: 16px;
    border-radius: 10px;
    border: none;
    font-weight: 700;
    width: 15rem;
}
.send-otp input::-webkit-inner-spin-button,
.send-otp input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.download-form input[type="number"],
.send-otp input[type="number"] {
    -moz-appearance: textfield;
}

.send-otp input {
    width: 100%;
}

.send-otp {
    display: flex;
    background: #2f2448;
    white-space: nowrap;
    border-radius: 10px;
    position: relative;
}
.send-otp input {
    margin: 0 !important;
}
.send-otp .btn {
    top: 0px;
    right: 0px;
    bottom: 0px;
    position: absolute;
    border-radius: 0px 1rem 1rem 0;
    padding: 0.3rem 0.5rem;
}
.send-otp input::-webkit-outer-spin-button,
.send-otp input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.send-otp input[type="number"] {
    -moz-appearance: textfield;
}

.download-apps,
.deposit-now {
    p,
    i {
        pointer-events: none;
    }
}

.week-winners-table-wrapper::-webkit-scrollbar {
    width: 0.6rem;
    border-radius: 1rem;
}

.week-winners-table-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 1rem;
}

.week-winners-table-wrapper::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    height: 3rem;
    box-shadow: inset 0px 4px 4px rgba(27, 128, 254, 0.3);
    background: #403079;
}

// THE POINT

.testimonial-item {
    background: rgba(27, 128, 254, 0.2);
    border: 2px solid #cfe8ff;
    border-radius: 0.9rem;

    .testimonial-owner {
        position: absolute;
        top: -15%;
        left: 50%;
        transform: translate(-50%, 0);
        border-radius: 50%;
        border: 1.2px solid rgba(86, 70, 146, 0.9);
    }
}

.related-articles-swiper {
    height: 300px;
    @include media-breakpoint-up(md) {
        height: 315px;
    }
    .article-wrapper,
    .article-item {
        height: 100%;
    }
}

.blog-list-item {
    border-bottom: 2px solid transparent;
    border-image: linear-gradient(276deg, #a96a14 4.48%, #1b80fe 68.02%);
    border-image-slice: 1;
}

#accordion.faqs {
    .show-faq,
    .hide-faq {
        display: none;
    }
    .question-set {
        border-bottom: 1px solid #5c43a9;
    }

    .card-toggler {
        cursor: pointer;
        position: relative;
        padding: 1rem 0;
        &:after {
            font-family: "Font Awesome 6 Free";
            position: absolute;
            top: 1rem;
            right: 0;
            pointer-events: none;
        }
        &[aria-expanded="false"]:after {
            content: "\2b";
        }
        &[aria-expanded="true"]:after {
            content: "\f068";
        }
    }
    .card.faq-item {
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 1rem;
        margin: 1rem 0;
        padding: 0.5rem;
    }
}
